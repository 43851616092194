import { dbUtil } from "../../../lib/dbutil";

const ID_COUNTRY_BRAZIL = 14;

export default async function getCountries() {
  let content = [];

  let sql = `SELECT c.*, ca.*
              FROM countries c, countries_access ca
              WHERE c.id = ca.idCountry
              AND c.id = ${ID_COUNTRY_BRAZIL}
              `;
  let results = await dbUtil.executeSql(sql);

  if (results) {
    for (let k in results) {
      sql = `SELECT *
              FROM countries_access_groups
              WHERE idCountryAccess = ${results[k].id}`;
      const results2 = await dbUtil.executeSql(sql);
      if (results2) {
        results[k]["groups"] = [];
        for (let k2 in results2) {
          results[k]["groups"].push(results2[k2]);
        }
      }
      content.push(results[k]);
    }
    return { content };
  }
}
