import { success } from "../types";
import { api } from "../../lib/api";
import { createApiAction } from "../createAction";
import getCountriesApiLocal from "../../database/api/common/getCountries";

import store from "../index";

export const GET_COUNTRIES = "login/GET_COUNTRIES";
export const SELECT_COUNTRY = "login/SELECT_COUNTRY";

export const EditionStatus = {
  PENDING: 1,
  SAVING: 2,
  SAVED: 3,
  FAILED: 4,
};

export const getCountries = () =>
  createApiAction(
    GET_COUNTRIES,
    () => getCountriesApiLocal(),
    () => api.get("/common/getCountries.php"),
  );
export const selectCountry = payload => dispatch => dispatch({ type: SELECT_COUNTRY, payload });

const initialState = {
  countries: [],
  country: null,
  error: null,
  isLoading: false,
};

function loginReducer(state = initialState, action) {
  switch (action.type) {
    case success(GET_COUNTRIES): {
      const {
        language: { locale },
      } = store.getState();
      const countries =
        action.payload.content.length > 0
          ? action.payload.content.filter(c => parseInt(c.access) === 1)
          : initialState.countries;
      const country =
        countries.length > 0 ? countries.find(c => c.language === locale) || countries[0] : {};
      if (state.countries.length) return state;
      return {
        ...state,
        countries,
        country,
      };
    }
    case SELECT_COUNTRY:
      return {
        ...state,
        country: action.payload,
        firstLoad: true,
      };
    default:
      return state;
  }
}

export default loginReducer;
