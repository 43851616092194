const env = process.env.REACT_APP_SM_ENV || "development";

const config = { 
  development: {
    UPLOAD_PATH: "http://localdev-teapi.smbrasil.com.br/node/uploads",
    BASE_URL: "http://localdev-olimpiadas.smbrasil.com.br",
    API_NODE: "http://localhost:3001/node",
    API: "http://localdev-teapi.smbrasil.com.br/cp",
    API_CI: "http://localdev-teapi.smbrasil.com.br/ci",
    GA_ID: "UA-78260084-3",
  },
  stage: {
    UPLOAD_PATH: "https://dev-teapi.smbrasil.com.br/node/uploads",
    BASE_URL: "https://dev-olimpiadas.smbrasil.com.br",
    API_NODE: "https://dev-teapi.smbrasil.com.br/node",
    API: "https://dev-teapi.smbrasil.com.br/cp",
    API_CI: "https://dev-teapi.smbrasil.com.br/ci",
    GA_ID: "UA-78260084-3",
  },
  hml: {
    UPLOAD_PATH: "https://hml-teapi.smbrasil.com.br/node/uploads",
    BASE_URL: "https://hml-olimpiadas.smbrasil.com.br",
    API_NODE: "https://hml-teapi.smbrasil.com.br/node",
    API: "https://hml-teapi.smbrasil.com.br/cp",
    API_CI: "https://hml-teapi.smbrasil.com.br/ci",
    GA_ID: "UA-78260084-3",
  },
  production: {
    UPLOAD_PATH: "https://teapi.smbrasil.com.br/node/uploads",
    BASE_URL: "https://olimpiadas.smbrasil.com.br",
    API_NODE: "https://teapi.smbrasil.com.br/node",
    API: "https://teapi.smbrasil.com.br/cp",
    API_CI: "https://teapi.smbrasil.com.br/ci",
    GA_ID: "UA-78260084-2",
  },
};


module.exports = config[env];
