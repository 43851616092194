import { combineReducers } from "redux";
import { success } from "./types";

/**
 * Reducers
 */
import app, { USER_LOGOUT } from "./app";
import language from "./language";
import login from "./login";

/*
 * routeReducer
 *
 * The reducer merges route location changes into our immutable state.
 * The change is necessitated by moving to react-router-redux@4
 *
 */

const appReducer = combineReducers({
  language,
  app,
  login,
});

const rootReducer = (state, action) => {
  if (action.type === success(USER_LOGOUT)) {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
