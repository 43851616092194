import Login from "../Login/Loadable";

export const securedRoutes = [
  {
    path: "/",
    exact: true,
    component: Login,
    allow: () => true,
    desktop: true,
  },
];

function toArray(object) {
  return Array.isArray(object) ? object : [object];
}

export function getAllowedRoutes({ routes, queryParams, role }) {
  const routesAllowedByRole = routes.filter(r => r.allow({ role }));
  const views = queryParams && queryParams.view ? toArray(queryParams.view) : [];
  if (views.length) {
    return routesAllowedByRole.filter(r => views.includes((r.path || r.to).split("/")[1]));
  }
  return routesAllowedByRole;
}
