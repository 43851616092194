/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 *
 */

// Needed for redux-saga es6 generator support
import "core-js";
// import "regenerator-runtime/runtime";

// Import all the third party stuff
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./store";
import initStore from "./store/init";
import "sanitize.css/sanitize.css";
import * as serviceWorker from "./serviceWorker";
import App from "./containers/App";
import LanguageProvider from "./containers/LanguageProvider";
import { translationMessages } from "./i18n";

import "./index.css";

// Create redux store with history
const MOUNT_NODE = document.getElementById("root");

const render = messages => {
  ReactDOM.render(
    <Provider store={store}>
      <LanguageProvider messages={messages}>
        <App />
      </LanguageProvider>
    </Provider>,
    MOUNT_NODE,
  );
};

if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(["./i18n", "./containers/App"], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render(translationMessages);
  });
}

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  new Promise(resolve => {
    resolve(import("intl"));
  })
    .then(() => Promise.all([import("intl/locale-data/jsonp/en.js")]))
    .then(() => render(translationMessages))
    .catch(err => {
      throw err;
    });
} else {
  render(translationMessages);
}

// // Install ServiceWorker and AppCache in the end since
// // it's not most important operation and if main code fails,
// // we do not want it installed
// if (process.env.NODE_ENV === "production") {
//   require("offline-plugin/runtime").install(); // eslint-disable-line global-require
// }

initStore(store);

// if (offline) {
//   const user = dao.findLocal();
//   if (user) {
//     store.dispatch({ type: 'USER_LOGIN_SUCCESS', data: user });
//   }
// }

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
