import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Loader from "../../components/Loader";
import { externalAuth, educamosAuth } from '../../store/app';

// function toArray(object) {
//   return Array.isArray(object) ? object : [object];
// }

class AuthRoute extends Component {
  componentDidMount() {
    this.authorize();
  }

  componentDidUpdate() {
    const { history, user, authorizing, ssoUrl } = this.props;

    console.log("redirect url");

    if (user) {
      // history.push(`/${redirectUrl}`);
    } else if (authorizing === false) {
      if (ssoUrl !== '') {
        console.log('SSO done.');
        window.opener.postMessage({ type: 'olimpiadasSsoSuccess', data: ssoUrl }, '*');
        // eslint-disable-next-line no-restricted-globals
        self.close();
      } else if (ssoUrl === '') {
        console.log('SSO error.');
        window.opener.postMessage({ type: 'olimpiadasSsoError' }, '*');
        // eslint-disable-next-line no-restricted-globals
        self.close();
      } else if (document.referrer) {
        //eslint-disable-next-line
        window.location.href = document.referrer;
      } else {
        history.push("/login");
      }
    }
  }

  authorize = () => {
    const { history, queryParams } = this.props;
    const educamosCode = queryParams?.code;
    if (educamosCode) {
      this.props.educamosAuth({ educamosCode });
    } else {
    // if (token) {
    //   this.props.externalAuth({ token });
    // } else if (appToken) {
    //   this.props.externalAuth({ appToken });
    // } else {
    // }
      history.push("/login");
    }
  };

  render() {
    return <Loader show />;
  }
}

AuthRoute.propTypes = {
  history: PropTypes.object.isRequired,
  queryParams: PropTypes.object.isRequired,
  externalAuth: PropTypes.func.isRequired,
  educamosAuth: PropTypes.func.isRequired,
  authorizing: PropTypes.bool,
  user: PropTypes.object,
};

export default withRouter(connect(({ app }) => ({ ...app }), { externalAuth, educamosAuth })(AuthRoute));
