import React, { Component, Fragment } from "react";
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import { FormattedMessage } from "react-intl";

import Login from "../Login/Loadable";
import SecuredRoutes from "./SecuredRoutes";
import AuthRoute from "./AuthRoute";
import Analytics from "./Analytics";

import messages from "./messages";

import "../../assets/scss/main.scss";

class App extends Component {
  componentDidMount() {
    console.log(`Running in ${process.env.REACT_APP_SM_ENV} environment.`);
  }

  render() {
    return (
      <Fragment>
        <Analytics />
        <FormattedMessage {...messages.title}>
          {title => (
            <Helmet>
              <title>{title}</title>
            </Helmet>
          )}
        </FormattedMessage>
        <Router>
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/auth" component={AuthRoute} />
            <Route path="/oauth-educamos" component={AuthRoute} />
            <SecuredRoutes />
          </Switch>
        </Router>
      </Fragment>
    );
  }
}

export default App;
