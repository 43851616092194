import db from "../../../lib/dbutil";
import uuid from "uuid";
import omit from "lodash/omit";

const sessionFields = [
  "id",
  "token",
  "abbreviation",
  "countryName",
  "email",
  "firstName",
  "lastName",
  "idCountry",
  "idSchool",
  "idType",
  "idUser",
  "insertedFrom",
  "language",
  "mdlUserGuid",
  "photo",
  "schoolName",
  "shareMyQuestions",
  "timezone",
];

export async function auth() {
  let sql = `SELECT name FROM sqlite_master WHERE type='table' AND name='sessions';`;
  const session = await db.query(sql);

  let user = null;
  let token = null;

  if (session) {
    sql = `
      SELECT 
        ${sessionFields}    
      FROM
          sessions
      LIMIT 1
      `;
    [user] = await db.query(sql);
    if (user) {
      token = user.token;
    }
  }
  return { user, token: token };
}

export async function register(user) {
  const defaults = { id: uuid(), idType: 3 };
  const data = {
    ...defaults,
    ...omit(user, "password"),
    firstName: user.firstname,
    lastName: user.lastname,
    abbreviation: user.idCountry === 14 ? "BR" : null,
  };
  // console.log(data);
  const fields = Object.keys(data);
  const values = Object.values(data);
  const sqlDrop = `DROP TABLE IF EXISTS sessions`;
  const sqlCreate = `CREATE TABLE sessions (${sessionFields})`;
  const sql = `INSERT INTO sessions (${fields}) VALUES (${fields.map(_ => "?").join(",")})`;
  await db.query(sqlDrop);
  await db.query(sqlCreate);
  await db.query(sql, values);
  return auth();
}
