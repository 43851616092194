import { nodeApi, api, apiCi } from "../../lib/api";
import { success, failure, pending } from "../types";
import queryString from "query-string";
import createAction, { createApiAction } from "../createAction"; //{ createApiAction }
import * as authLocalApi from "../../database/api/auth/auth";

export const USER_LOGIN = "app/USER_LOGIN";
export const USER_AUTH = "app/USER_AUTH";
export const USER_LOGOUT = "app/USER_LOGOUT";
export const USER_AUTH_EDUCAMOS = "app/USER_AUTH_EDUCAMOS";

const isDev = process.env.NODE_ENV === "development";

export const Role = {
  ADMINISTRADOR: 1,
  COORDINATOR: 2,
  TEACHER: 3,
  STUDENT: 4,
  AUTHOR: 5,
  EDITOR: 6,
};

function getRole(id) {
  return Object.keys(Role)
    .map(k => Role[k])
    .find(roleId => roleId === parseInt(id, 0));
}

export const auth = () =>
  createApiAction(
    USER_AUTH,
    () => authLocalApi.auth(),
    () => nodeApi.get("/auth"),
  ); // use cookie

export const externalAuth = data => createAction(USER_AUTH, nodeApi.post("/auth", data));

export const educamosAuth = data => createAction(USER_AUTH_EDUCAMOS, apiCi.post("/oautheducamos/olimpiadas", data));

export const login = payload =>
  createAction(USER_LOGIN, apiCi.post("/login", { ...payload, idAccess: 25 }));

export const logout = () => createAction(USER_LOGOUT, nodeApi.get("/logout"));

const initialState = {
  role: null,
  user: null,
  token: null,
  authorizing: null,
  error: {},
  // eslint-disable-next-line no-restricted-globals
  queryParams: queryString.parse(location.search.replace("?", "")),
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // case success(USER_AUTH):
    case success(USER_LOGIN): {
      const { token, user, ssoUrl } = action.payload;
      return {
        ...state,
        user,
        token,
        ssoUrl,
        error: { ...state.error, login: undefined },
      };
    }
    case success(USER_AUTH_EDUCAMOS): {
      const { ssoUrl } = action.payload;
      return {
        ...state,
        authorizing: false,
        ssoUrl
      };
    }
    case pending(USER_AUTH):
    case pending(USER_AUTH_EDUCAMOS):
    case pending(USER_LOGIN):
      return { ...state, authorizing: true };
    case failure(USER_AUTH):
    case failure(USER_AUTH_EDUCAMOS):
      return { ...state, authorizing: false };
    case failure(USER_LOGIN):
      return {
        ...state,
        authorizing: false,
        error: {
          ...state.error,
          login: {
            status: action.status,
            loginErrorAttempts: action.payload.error.loginErrorAttempts,
            message: action.payload.error.message,
            isUserCi: action.payload.error.isUserCi,
          },
        },
      };
    case success(USER_LOGOUT): {
      sessionStorage.clear(); // retrocompatible
      if (isDev) {
        localStorage.clear();
      }
      return { ...initialState };
    }
    default:
      return state;
  }
};

export default reducer;
