import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Route, withRouter, Redirect, Switch } from "react-router-dom";
import { connect } from "react-redux";
import Loader from "../../components/Loader";
import { logout, auth } from "../../store/app";
import { getCountries } from "../../store/login";
import IdleTimer from "react-idle-timer";

import { securedRoutes, getAllowedRoutes } from "./routes";

const authorizedPaths = ["/login", "/auth"];

const SecuredRoutes = ({
  role,
  queryParams,
  user,
  logout,
  getCountries,
  history,
  location,
  authorizing,
  ...props
}) => {
  const [routes, setRoutes] = useState(securedRoutes);

  const idleTimer = useRef(null);

  function handleOnAction(event) {}

  function handleOnActive(event) {}

  async function handleOnIdle(event) {
    await logout();
    if (document.referrer) {
      window.location.href = document.referrer;
    } else {
      history.push("/login");
    }
  }

  useEffect(() => {
    function redirectToLogin() {
      const path = location.pathname;
      if (!authorizedPaths.includes(path)) {
        const redirectTo = path.replace("/", "");
        const url = `/login${redirectTo ? `?p=${redirectTo}` : ""}`; // make possible to return to last page
        history.push(url);
      }
    }

    if (authorizing === false && !user) {
      redirectToLogin();
    }
  }, [authorizing, history, location.pathname, user]);

  useEffect(() => {
    const path = location.pathname;
    if (!user && !authorizedPaths.includes(path)) {
      // props.auth();
      history.push("/login");
    }
  }, [history, location.pathname, props, user]);

  useEffect(() => {
    if (user) {
      setRoutes(
        getAllowedRoutes({
          routes: securedRoutes,
          queryParams,
          role,
        }),
      );
    }
  }, [queryParams, role, user]);

  function handleSubmit() {
    props.auth();
  }

  if (!user) return <Loader show />;
  return (
    <>
      <IdleTimer
        ref={idleTimer}
        timeout={600000} /* 10 minutes in miliseconds */
        onActive={handleOnActive}
        onIdle={handleOnIdle}
        onAction={handleOnAction}
        debounce={250}
      />
      <Switch>
        {routes.map(route => (
          <Route key={route.path} {...route} />
        ))}
        <Route render={() => <Redirect to="/" />} />
      </Switch>
    </>
  );
};

SecuredRoutes.propTypes = {
  user: PropTypes.object,
  history: PropTypes.object.isRequired,
  authorizing: PropTypes.bool,
  location: PropTypes.object.isRequired,
  auth: PropTypes.func.isRequired,
};

const mapStateToProps = ({ app, assessments }) => ({ ...app, ...assessments });

export default withRouter(
  connect(mapStateToProps, {
    auth,
    getCountries,
    logout,
  })(SecuredRoutes),
);
