import get from "lodash/get";
import { useLocalApi } from "./device";

const createAction = (type, promise, ...args) => dispatch => {
  dispatch({
    type: `${type}_PENDING`,
    ...(args[2] || {}),
  });
  return promise
    .then(response => {
      dispatch({
        type: `${type}_SUCCESS`,
        payload: response.data,
        ...(args[0] || {}),
      });
    })
    .catch(error => {
      console.error(error);
      dispatch({
        type: `${type}_FAILURE`,
        payload: error.response ? error.response.data : error,
        status: error.response ? error.response.status : null,
        ...(args[1] || {}),
      });
    });
};

export const createApiAction = (
  type,
  localApiPromiseFn,
  remoteApiPromiseFn,
  ...args
) => dispatch => {
  dispatch({
    type: `${type}_PENDING`,
    ...(args[2] || {}),
  });
  if (!remoteApiPromiseFn) remoteApiPromiseFn = localApiPromiseFn;
  //verifica o tipo de ambiente, desktop/offline usa a api local, se não usa a api remota
  const promise = useLocalApi ? localApiPromiseFn() : remoteApiPromiseFn();
  return promise
    .then(response => {
      dispatch({
        type: `${type}_SUCCESS`,
        payload: useLocalApi ? response : response.data,
        ...(args[0] || {}),
      });
    })
    .catch(error => {
      console.error(error);
      const payload = useLocalApi ? error : get(error, "response.data") || error;
      dispatch({
        type: `${type}_FAILURE`,
        payload,
        error: payload,
        status: useLocalApi ? error.status : get(error, "response.status") || null,
        ...(args[1] || {}),
      });
    });
};

export function dao(DAOClass, method, payload) {
  console.error("DAO is deprecated. Use local api instead with createApiAction");
  return () =>
    new Promise((resolve, reject) => {
      const daoFn = new DAOClass();
      daoFn[method](payload, data => resolve(data), err => reject(err));
    });
}

export const createOfflineAction = (type, daoFn, promise, ...args) => dispatch => {
  dispatch({
    type: `${type}_PENDING`,
    ...(args[2] || {}),
  });
  if (useLocalApi) {
    return daoFn()
      .then(data => {
        dispatch({
          type: `${type}_SUCCESS`,
          payload: data,
          ...(args[0] || {}),
        });
      })
      .catch(err => {
        dispatch({
          type: `${type}_FAILURE`,
          payload: err,
          ...(args[1] || {}),
        });
      });
  }
  return promise
    .then(response => {
      dispatch({
        type: `${type}_SUCCESS`,
        payload: response.data,
        ...(args[0] || {}),
      });
    })
    .catch(error => {
      console.error(error);
      dispatch({
        type: `${type}_FAILURE`,
        payload: error.response ? error.response.data : error,
        status: error.response ? error.response.status : null,
        ...(args[1] || {}),
      });
    });
};

export const promisify = actions => dispatch =>
  Object.keys(actions)
    .map(k => ({
      [k]: payload => {
        if (typeof actions[k] !== 'function') {
          throw new Error(`${k} is not a function to promisify`);
        }
        return new Promise((resolve, reject) =>
          dispatch(actions[k]({ ...payload, resolve, reject })),
        );
      },
    }))
    .reduce((a1, a2) => ({ ...a1, ...a2 }), {});


export default createAction;
